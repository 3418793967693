const customErrorMiddleware = store => next => action => {
  if (action.type.toLowerCase().endsWith('failure')) {
    const isType =
      action.type.toUpperCase().startsWith('FETCH_DATASET_MAX_UPLOAD') ||
      action.type.toUpperCase().startsWith('CREATE_A_NEW_PROJECT') ||
      action.type.toUpperCase().startsWith('BUILD_APP_TEMPLATE') ||
      //action.type.toUpperCase().startsWith('FETCH_MY_DATASETS') ||
      //action.type.toUpperCase().startsWith('DATASET_EXISTS_PRECHECK') ||
      action.type.toUpperCase().startsWith('FETCH_ITR_SAMPLE_MODEL')

    if (
      isType &&
      action &&
      action.payload &&
      action.payload.response &&
      action.payload.response.deploymentType === 'UBER_DEPLOYMENT' &&
      action.payload.response.status === 'NOT_FOUND'
    ) {
      store.dispatch({ type: 'FETCH_DOWN_UBER_SUCCESS', payload: { status: 'SHOWUBERISDOWN' } })
    }
  }

  return next(action)
}
export default customErrorMiddleware
