import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NormalButton } from '../../ReusableCode/EmlyButton'

class MainErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: null, errorInfo: null }
    this.goBack = this.goBack.bind(this)
  }
  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true, errorInfo: errorInfo })
  }

  goBack = () => {
    this.props.history.goBack()
    setTimeout(() => {
      this.reload()
    }, 2000)
  }

  reload = () => {
    window.location = '/'
  }

  render() {
    if (this.state.hasError || this.props.error) {
      return (
        <div className="em-row ErrorB">
          <div className="em-flex" style={{ flexWrap: 'nowrap', width: '100%' }}>
            <img src={require('../../../../images/onBoard/launch.png')} style={{ width: 100 }} />
            <h1>Uh-oh! Service hiccup detected!</h1>
          </div>
          <div className="inn-cop">
            We are currently unable to serve this request. Try reloading page, if problems persist,{' '}
            <b>contact support@emlylabs.com</b>
          </div>
          <h3 className="inn-h3">Thank you for your patience while we maneuver through this glitch!</h3>
          <div className="em-row err-reload">
            <NormalButton
              className="em-btn emly-btn-danger"
              onClick={() => window.location.reload(true)}
              title={'Reload'}
              style={{ width: '300px' }}
            >
              <i class="fa fa-refresh"></i> Reload
            </NormalButton>
          </div>
        </div>
      )
    }
    return this.props.children
  }
}
export default connect(state => state)(MainErrorBoundary)
