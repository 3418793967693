import React, { useState } from 'react'
import Select from 'react-select'
import '../../components-examples/ReusableCode/emlyMain.css'
import PopUp from '../PopUP'
import './account-info.css'

const BillingPopUp = ({ upgradePlan, setUpgradePlans }) => {
  const [plan, setPlan] = useState(options[0])

  return (
    <div className="billingpopup">
      <PopUp title={null} loadingCancel={true} bottomButton={false} bottomButtonCancel={false} width={'800px'}>
        <div className="em-row em-flex">
          <div className="bg-img">
            <img
              style={{ width: '100%', height: '100%', borderTopLeftRadius: '14px', borderBottomLeftRadius: '14px' }}
              src={require('../../../images/SignupImages/BackGroundImage.png')}
            />
          </div>
          <div className="em-row" style={{ padding: '10px' }}>
            <h1 style={{ margin: '10px auto', fontSize: '20px' }}>Elevate Your AI Experience with Your Own Account</h1>
            <h2 style={{ margin: '10px auto', fontSize: '16px' }}>Transition Seamlessly from a Member to a Leader.</h2>
            <ul>
              <li>Access to all Features</li>
              <li>Control and Customization</li>
              <li>Priority Support and Resources</li>
            </ul>
            <h3 style={{ margin: '10px auto' }}>Choose the EMLY plan that suits your needs and start your journey. </h3>
            <div className="try-Now em-row" style={{ marginTop: '10px' }}>
              <Select
                value={plan}
                onChange={value => setPlan(value)}
                options={options}
                placeholder="Choose an Emly-Plan"
              />
            </div>
            <div className="btnsection">
              <a href={`${window.REACT_APP_PRICING_LINK}`} target="_blank">
                View Plans and Pricing Details&nbsp;&nbsp;<i class="fa fa-external-link" aria-hidden="true"></i>
              </a>

              <div style={{ display: 'flex', justifyContent: 'end', gap: '10px', float: 'right', paddingTop: '16px' }}>
                <button
                  className={'em-btn'}
                  disabled={plan == null}
                  onClick={e => {
                    upgradePlan(plan)
                  }}
                >
                  Subscribe
                </button>
                <button
                  className={'em-btn em-danger'}
                  onClick={e => {
                    setUpgradePlans(false)
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </PopUp>
    </div>
  )
}

export default BillingPopUp

const options = window.REACT_APP_PLAN_LIST.map(it => {
  return { label: it.name, value: it.planId }
})
