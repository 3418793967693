import React from 'react'
import MenuItems from './MenuItems'

const Dropdown = ({ submenus, dropdown, depthLevel, dispatch, disabled, showSwitch }) => {
  depthLevel = depthLevel + 1
  const dropdownClass = depthLevel > 1 ? 'dropdown-submenu' : ''
  return (
    <ul className={`dropdown ${dropdownClass} ${dropdown ? 'show' : ''}`}>
      {submenus.map((submenu, index) => (
        <MenuItems
          disabled={disabled}
          items={submenu}
          depthLevel={depthLevel}
          dispatch={dispatch}
          showSwitch={showSwitch}
        />
      ))}
    </ul>
  )
}

export default Dropdown
