import React from 'react'
import detectOutsideEvent from '../../components-examples/utils/detectOutsideEvent'
import './index.css'

const Tabs = ({
  tabData = [],
  active,
  tabWidth = 300,
  tabText = false,
  tabTitle = true,
  kabab = false,
  callDirect = false,
  leftChild = null,
  rightChild = false,
  setActive,
  whichGroup
}) => {
  const isTabData = tabData !== undefined || tabData !== null || tabData.length !== 0
  const [kababDiv, setKabab] = React.useState(false)

  const impactRef = React.useRef()
  detectOutsideEvent(impactRef, () => setKabab(false))

  const groupedData = tabData.reduce((groups, item) => {
    const key = item.category
    if (!groups[key]) {
      groups[key] = []
    }
    groups[key].push(item)
    return groups
  }, {})

  if (isTabData) {
    const tabsKeys = Object.keys(groupedData).filter(i => i !== 'undefined')
    const tabcomponent = tabData.reduce((acc, curr) => {
      const { component, id } = curr
      acc[id] = { component }
      return acc
    }, {})
    return (
      <div className="outer-div-tab">
        {kabab ? (
          <>
            <div className="kabab-container-main">
              <div className="kabab-container-leftChild">{leftChild}</div>
              <div className="kabab-container" ref={impactRef}>
                {rightChild}
                <button className="kabab-container-button" onClick={() => setKabab(!kababDiv)}>
                  <img style={{ width: 17, height: 15, marginTop: -5 }} src={require('../../../images/burger.png')} />
                </button>

                {kababDiv && (
                  <div className="tab-kabab">
                    <div className="tab-kabab-div">
                      {tabsKeys.map(i => {
                        return (
                          <div style={{ marginBottom: 10 }}>
                            {i === 'Actions' && (
                              <div className="tab-key">
                                <p className="tab-key-text">{i}</p>
                              </div>
                            )}
                            {groupedData[i]
                              .filter(i => i.id !== 'view_report')
                              .map(t => {
                                return (
                                  <div
                                    style={{
                                      cursor: t.disabled ? 'not-allowed' : ''
                                    }}
                                    onClick={() => {
                                      setKabab(false)
                                      t.onClickTab(t.id)
                                    }}
                                    className={t.id === active ? 'tab-kabab-div-item-highlight' : 'tab-kabab-div-item'}
                                  >
                                    <i style={{ marginRight: 10 }} className={t.icon}>
                                      {' '}
                                    </i>{' '}
                                    {t.name}
                                  </div>
                                )
                              })}
                          </div>
                        )
                      })}
                      {tabData
                        .filter(i => i.category === undefined)
                        .map(tab => {
                          return (
                            <div
                              style={{
                                cursor: tab.disabled ? 'not-allowed' : ''
                              }}
                              onClick={() => {
                                setKabab(false)
                                !tab.disabled && tab.onClickTab(tab.id)
                              }}
                              className={tab.simple ? 'tab-kabab-div-item reports' : 'kabab-action tab-space'}
                            >
                              {tab.simple && (
                                <i style={{ marginRight: 10, marginTop: 4 }} className={tab.icon}>
                                  {' '}
                                </i>
                              )}
                              <p className={tab.simple ? '' : 'advanced-mode'}>{tab.name}</p>
                              {!tab.simple && (
                                <i style={{ marginRight: 10, fontSize: 30 }} className={tab.icon}>
                                  {' '}
                                </i>
                              )}
                            </div>
                          )
                        })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div>
            {tabTitle && (
              <div className={tabText ? 'tab-div-text' : 'tab-div'}>
                {' '}
                {tabData.map(tab => {
                  return <Tab tab={tab} active={active} tabWidth={tabWidth} tabText={tabText} whichGroup={whichGroup} />
                })}{' '}
              </div>
            )}
          </div>
        )}
        {callDirect === false && tabcomponent[active] !== undefined ? tabcomponent[active].component : <p></p>}
      </div>
    )
  } else {
    return <div />
  }
}
export default Tabs

const Tab = ({ active, tab, tabWidth, tabText, whichGroup }) => {
  const { name, id, onClickTab, icon, number, color } = tab
  const isPointer = whichGroup !== undefined && whichGroup !== null ? 'isPointer' : ''
  return (
    <div
      style={{ width: tabWidth }}
      onClick={() => onClickTab(id)}
      className={
        active === id ? (tabText ? 'tab-active-text' : `tab-active ${isPointer}`) : tabText ? 'tab-text' : 'tab'
      }
    >
      {icon && icon === 'LEARN_FLOW' ? (
        <img className="tab-ico" src={require('../../../images/learn-flow.svg')} />
      ) : icon === 'BUILD_FLOW' ? (
        <img className="tab-ico" src={require('../../../images/build-flow.svg')} />
      ) : (
        icon && <i style={{ marginRight: 2, color: color ? color : 'grey' }} class={icon} aria-hidden="true"></i>
      )}
      {name}
      {number > 0 && (
        <p style={{ marginLeft: 2, color: 'red' }} class={icon} aria-hidden="true">
          {number}
        </p>
      )}
    </div>
  )
}
