import React from 'react'
import { NormalButton } from '../api/components-examples/ReusableCode/EmlyButton'
import './index.css'

const MainError = () => {
  return (
    <div className="em-row ErrorB" style={{ width: '55%' }}>
      <div className="em-flex" style={{ flexWrap: 'nowrap', width: '100%' }}>
        <img src={require('../images/onBoard/launch.png')} style={{ width: 100 }} />
        <h1>Uh-oh! seems like we hit a snag</h1>
      </div>
      <div className="inn-cop">
        We apologize for the inconvenience, but it appears there's been an error. Our team is on it and will have things
        back to normal ASAP. Feel free to refresh the page or reach out to support if you need immediate assistance. ,
        if problems persist, <b>contact support@emlylabs.com</b>
      </div>
      <h3 className="inn-h3">Thank you for your patience while we maneuver through this glitch!</h3>
      <div className="em-row err-reload">
        <NormalButton
          className="em-btn emly-btn-danger"
          onClick={() => window.location.reload(true)}
          title={'Reload'}
          style={{ width: '300px' }}
        >
          <i class="fa fa-refresh"></i> Reload
        </NormalButton>
      </div>
    </div>
  )
}

export default MainError
