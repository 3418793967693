import React from 'react'
import { connect } from 'react-redux'
import '../api/components-examples/Admin/admin.css'
import TopLayer from '../api/components-examples/Admin/topLayerComponent'
import MainErrorBoundaryMainContent from '../api/components-examples/utils/ErrorBoundary/MainErrorBoundaryMainContent'
import NewVerticalNavigation from '../VerticalNavigation'
import './index.css'
import WaitingLoader from '../UI-components/Templates/WaitingLoader'

const MainDashboardWrapper = ({
  items,
  activeItem,
  myProfile,
  location,
  changeCurrentTab,
  myTemplates,
  myApps,
  dispatch,
  checkUberDeployment,
  myReccTemplates,
  api,
  collapse,
  children,
  history,
  dataset,
  myUserAccountInfo,
  persisted,
  error,
  theme,
  buildInfo,
  userProjectPermissions
}) => {
  const [themes, setThme] = React.useState(theme !== undefined && theme.action === null ? 'light' : theme.action.theme)
  React.useEffect(() => {
    if (theme.action !== null) {
      setThme(theme.action.theme)
    }
  })
  const userName =
    persisted.userInformation !== null && persisted.userInformation.name !== undefined
      ? persisted.userInformation.name
      : ''
  return (
    <>
      <TopLayer themes={themes} />
      <MainErrorBoundaryMainContent history={history} error={error}>
        {persisted.userId ? (
          <div className="body-content">
            <div className={collapse ? ' NewVerticalNavigation-collapse' : 'NewVerticalNavigation'}>
              <NewVerticalNavigation
                items={items}
                history={history}
                dataset={dataset}
                myUserAccountInfo={myUserAccountInfo}
                activeItem={activeItem}
                myProfile={myProfile}
                currentPath={location.pathname}
                changeCurrentTab={changeCurrentTab}
                myApps={myApps}
                myTemplates={myTemplates}
                checkUberDeployment={checkUberDeployment}
                myReccTemplates={myReccTemplates}
                dispatch={dispatch}
                api={api}
                theme={themes}
                buildInfo={buildInfo}
                userProjectPermissions={userProjectPermissions}
              />
            </div>
            {api === null ? null : (
              <div className={collapse ? 'right-portion-collapse' : 'right-portion'}>{children}</div>
            )}
          </div>
        ) : (
          <div className="centreLoadingSpinner" style={{ height: 'calc( 100vh - 50px )' }}>
            <WaitingLoader message={`Hey ${userName} We're setting up your workspace. This shouldn't take long 😉`}/>            
          </div>
        )}
      </MainErrorBoundaryMainContent>
    </>
  )
}

const mapStateToProps = state => ({
  api: state.api,
  apiError: state.apiError,
  myProfile: state.myProfile,
  myHelpLinks: state.myHelpLinks,
  datoinServices: state.datoinServices,
  bgStatus: state.bgStatus,
  myApps: state.myApps,
  myTemplates: state.myTemplates,
  myReccTemplates: state.myReccTemplates,
  checkUberDeployment: state.checkUberDeployment,
  theme: state.theme,
  currentContext: state.currentContext,
  myUserAccountInfo: state.myUserAccountInfo,
  dataset: state.emlycreateFetchMyNewDatasetsRSAAReducer.dataset,
  collapse: state.emlyFrameWork.collapse,
  persisted: state.userProfilePersisted,
  buildInfo: state.buildInfo,
  userProjectPermissions: state.userProjectPermissions
})

export default connect(mapStateToProps)(MainDashboardWrapper)
