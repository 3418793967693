import React from 'react'
import Progress from './Progress'

const statusSteps = [
  { name: 'REQUESTING', status: false, message: 'Requesting', progress: 5 },
  { name: 'DEPLOYING', status: false, message: 'Preparing', progress: 30 },
  { name: 'SUBMITTED', status: false, message: 'Initializing', progress: 50 },
  { name: 'INITIALISED', status: false, message: 'Initialized', progress: 70 },
  { name: 'VERIFYING', status: false, message: 'Verifying', progress: 90 },
  { name: 'DEPLOY_SUCCESS', status: false, message: 'Completed', progress: 100 }
]

export const RunningInstance = props => {
  return (
    <div className="rsc-instance">
      <div className="rsc-image" style={{ marginTop: '5px' }}>
        <div className="rsc-load"></div>
      </div>

      <div className="rsc-content">
        <div className="rsc-title">Restoring Emly Core Engine Service</div>
        <div className="rsc-progress">
          <span>
            {statusSteps.find(i => i.name === props.uberService.status).message}
            <div class="rsc-dots-container">
              <div class="rsc-dot"></div>
              <div class="rsc-dot"></div>
              <div class="rsc-dot"></div>
            </div>
          </span>
          <Progress progress={statusSteps.find(i => i.name === props.uberService.status).progress} />
          <p className="rsc-info">This should take less than a minute</p>
        </div>
      </div>
    </div>
  )
}
