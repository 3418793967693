import React from 'react'
import './customMenu.css'
import MenuItems from './MenuItems'

const Navbar = props => {
  return (
    <div className="uppernav-menu-component">
      <div className="menus">
        {props.menuItems.map((menu, index) => {
          const depthLevel = 0
          return (
            <MenuItems
              disabled={props.disabled}
              items={menu}
              key={index}
              depthLevel={depthLevel}
              dispatch={props.dispatch}
              showSwitch={props.showSwitch}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Navbar
