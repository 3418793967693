import React, { useState } from 'react'

const RestoreInstance = props => {
  const [showMore, setShowMore] = useState(false)

  const toggleShowMore = () => {
    setShowMore(prev => !prev)
  }

  return (
    <div className="rsc-instance">
      <div className="rsc-image">
        <svg viewBox="0 0 64 64">
          <g id="Flat">
            <path
              d="m53.8 59h-43.6a7.2 7.2 0 0 1 -7.2-7.2 7.2 7.2 0 0 1 .847-3.388l20.8-39a8.332 8.332 0 0 1 7.353-4.412 8.332 8.332 0 0 1 7.353 4.412l20.8 39a7.2 7.2 0 0 1 .847 3.388 7.2 7.2 0 0 1 -7.2 7.2z"
              fill="#7586a6"
            />
            <path
              d="m10.2 59h43.6a7.2 7.2 0 0 0 6.351-10.586l-20.8-39a8.188 8.188 0 0 0 -.535-.855 8.3 8.3 0 0 0 -12.171 2.855l-20.8 39a7.2 7.2 0 0 0 .361 7.378 7.168 7.168 0 0 0 3.994 1.208z"
              fill="#5a6e8c"
            />
            <path
              d="m10.2 55a3.2 3.2 0 0 1 -2.821-4.7l20.8-39a4.332 4.332 0 0 1 7.646 0l20.8 39a3.2 3.2 0 0 1 -2.825 4.7z"
              fill="#ffc83e"
            />
            <path
              d="m35.907 11.451a4.311 4.311 0 0 0 -5.73 1.844l-20.8 39a3.209 3.209 0 0 0 -.377 1.505 3.156 3.156 0 0 0 .181 1.017 3.156 3.156 0 0 0 1.019.183h43.6a3.2 3.2 0 0 0 2.822-4.7z"
              fill="#ffdc5e"
            />
            <g fill="#5a6e8c">
              <path d="m32 21a2.853 2.853 0 0 1 2.85 3l-.75 15a2.1 2.1 0 0 1 -2.1 2 2.1 2.1 0 0 1 -2.1-2l-.75-15a2.853 2.853 0 0 1 2.85-3z" />
              <circle cx="32" cy="47" r="2" />
            </g>
          </g>
        </svg>
      </div>

      <div className="rsc-content">
        <div className="rsc-title">{props.instance['title']}</div>
        <div className="rsc-description">{props.instance['description']}</div>
        {showMore && (
          <div className="rsc-vmore">
            <p className="rsc-line"></p>
            <ul className="rsc-ul">
              <li>
                <span>Instance Size</span> : {props.instance['size']} GB
              </li>
              <li>
                <span>Instance Policy</span> : Shutdown in {props.instance['ttl']} minutes of non usage
              </li>
            </ul>

            <p className="rsc-note">
              <span>Note</span>: If you want to adjust the instance size or shutdown policy, you can easily manage that
              through the admin console.
            </p>
          </div>
        )}
        <div className="rsc-amore">
          <button className="rsc-link" onClick={toggleShowMore}>
            {showMore ? (
              <>
                Hide Details
                <i class="fa fa-angle-down"></i>
              </>
            ) : (
              <>
                View More Details
                <i class="fa fa-angle-up"></i>
              </>
            )}
          </button>
          <div className="rsc-actions">
            {props.showCancel && (
              <button className="rsc-cancel" onClick={props.onCancel}>
                Cancel
              </button>
            )}
            <button className="rsc-restore" onClick={props.restoreInstance()}>
              <svg viewBox="0 0 30.143 30.143">
                <g>
                  <path
                    d="M20.034,2.357v3.824c3.482,1.798,5.869,5.427,5.869,9.619c0,5.98-4.848,10.83-10.828,10.83
		c-5.982,0-10.832-4.85-10.832-10.83c0-3.844,2.012-7.215,5.029-9.136V2.689C4.245,4.918,0.731,9.945,0.731,15.801
		c0,7.921,6.42,14.342,14.34,14.342c7.924,0,14.342-6.421,14.342-14.342C29.412,9.624,25.501,4.379,20.034,2.357z"
                  />
                  <path
                    d="M14.795,17.652c1.576,0,1.736-0.931,1.736-2.076V2.08c0-1.148-0.16-2.08-1.736-2.08
		c-1.57,0-1.732,0.932-1.732,2.08v13.496C13.062,16.722,13.225,17.652,14.795,17.652z"
                  />
                </g>
              </svg>
              Restore
            </button>
          </div>
        </div>

        <div className="rsc-support">
          If service does not restore promptly, please report it on{' '}
          <a href="https://emlylabs.com/support/" target="_blank">
            support page.
          </a>
        </div>
      </div>
    </div>
  )
}

export default RestoreInstance
