const getRedirectUrl = realm => {
  switch (realm) {
    case 'acad1':
      return 'https://app.acad1.emlylabs.com'
    case 'pre':
      return 'https://pre-app.datoin.com'
    case 'emly':
      return 'https://app.emlylabs.com/'
    default:
      return 'https://app.acad1.emlylabs.com'
  }
}

const environment = window.REACT_APP_GATEWAY_AUTH_REALM

const encodedRedirectUri = planId => {
  return encodeURIComponent(`${getRedirectUrl(environment.realm)}/onboarduser?ss=google&prodId=${planId}`)
}

export const getGoogleLink = planId => {
  return `${environment.url}/realms/${environment.realm}/protocol/openid-connect/auth?client_id=${
    environment.clientId
  }&redirect_uri=${encodedRedirectUri(planId)}&response_type=code&scope=openid&kc_idp_hint=google`
}

export const getlinkedinLink = planId => {
  return `${environment.url}/realms/${environment.realm}/protocol/openid-connect/auth?client_id=${
    environment.clientId
  }&redirect_uri=${encodedRedirectUri(planId)}&response_type=code&scope=openid&kc_idp_hint=linkedin-openid-connect`
}
